import React from 'react';
import { Link } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import '../App.css';
import { Project, Timeline } from "../components"
import { Workflow } from './Workflow';
import data from "../experience.json"

const { projects, companies } = data.work

export function DevExp() {

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 414px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const initialLanguageData = {
    'JavaScript': 0,
    'Swift': 0
  }

  const styles = {
    title: { 
        textAlign: isPortrait ? 'center' : 'left', 
        color: '#2E323A', 
        marginTop: 50, 
        marginBottom: 40
    },
  }
  

  const [selectedLanguage, setSelectedLanguage] = React.useState('JavaScript')

  const [selectedCompany, setSelectedCompany] = React.useState(null)
  const [projectsOfSelectedCompany, setProjectsOfSelectedCompany] = React.useState(projects)
  const [languageData, setLanguageData] = React.useState(initialLanguageData)
  React.useEffect(() => {
    if (selectedCompany) {
     
      setProjectsOfSelectedCompany(projects.filter(project => {
        console.log('company:', selectedCompany.name, 'project:', project.company, project.company === selectedCompany.name)
        return project.company === selectedCompany.name
      }))
    } else {
      setProjectsOfSelectedCompany(projects)
    }
    setSelectedLanguage(null)
  }, [selectedCompany])

  React.useEffect(() => {
    let languageData = initialLanguageData
    projectsOfSelectedCompany.map(project => {
      const { language } = project
      languageData[language] = languageData[language] + 1
    })
    setLanguageData(languageData)
  }, [projectsOfSelectedCompany])

  const filteredProjects = () => {
    return projectsOfSelectedCompany.filter(project => {
      if (selectedLanguage) {
        return project.language === selectedLanguage
      }
      return project
    })
  }

  const renderResponsibility = (type, content, isDevOps = false, link = null) => {
    return (
      <div style={{ display: 'flex', flexDirection: isPortrait && isMobileDevice ? 'column' : 'row', marginBottom: 20, width: isPortrait || isTabletOrMobileDevice || isDevOps ? '100%' : '50%'}}>
        <div style={{ width: isDevOps ? '30%' : '30%', maxWidth: 200 }}>
          <img src={require(`../assets/badges/${type}.png`)} style={{ width: '100%', height: 'auto' }} />
        </div>
        
        <div style={{ flex: 1}} >
          <p style={{ margin: 0, marginLeft: 20, marginRight: 20 }}>{content}</p>
          { link && 
            <div className='link-button'>
              <Link to="/workflow" activeClassName="link-button">Workflow Diagrams and Toolkits</Link>
            </div>
          }
        </div>
      </div>
    )
  }
  

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Timeline
          companies={companies}
          isSimplified={isPortrait && isMobileDevice}
          onSelect={(company) => {
              setSelectedCompany(company)
          }} 
        />
        {/* Responsibilities */}
        { selectedCompany && 
          <h1 style={styles.title}>
            { `${selectedCompany.isDevOps ? 'Dev ' : ''}` +  `Responsibilites in ${selectedCompany.name}`}
          </h1> 
        }
        { selectedCompany && 
          <div style={{ display: 'flex', flexDirection: isPortrait || isTabletOrMobileDevice ? 'column' : 'row', flexWrap: 'wrap' }}>
            { Object.entries(selectedCompany.responsibilities).map(([type, content]) => renderResponsibility(type, content)) }
          </div>
        }
        { selectedCompany && <h1 style={styles.title}>{`Projects Developed in ${selectedCompany.name}`}</h1> }

        {/* Projects */}
        <div style={{ marginBottom: 300 }}>
        { filteredProjects().map(project => (
            <Project key={project.name} project={project} isPortrait={isMobileDevice} remainOpen={ filteredProjects().length === 1 ? true : false }></Project>
        )) }
        </div>
    </div>
  );
}
