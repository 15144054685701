import React from 'react';
import logo from '../logo.svg';
import { useMediaQuery } from 'react-responsive'
import Collapse, { Panel } from 'rc-collapse';

// var Collapse = require('rc-collapse');
// var Panel = Collapse.Panel;
require('rc-collapse/assets/index.css');



export function Project(props) {
    const { project, remainOpen = false } =  props
    const { name, description, framework, language, icon, images, urls } = project

    const [isOpen, setIsOpen] = React.useState( remainOpen ? true : false)

    return (
        <Collapse
            accordion={true}
            onChange={() => { setIsOpen(!isOpen) }}
            style={{ width: '100%' }}
            defaultActiveKey={ remainOpen ? name : null}
        >
            <Panel
                key={name}
                showArrow={false}
                header={
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', minHeight: 100, marginRight: 10 }}>
                        <div style={{ width: 60, height: 60, borderRadius: '20%', borderWidth: 5, margin: 5, alignItems: 'center', overflow: 'hidden', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' }}>
                            <img src={require(`../assets/icons/${icon}`)} style={{ width: 60, height: 60 }} />
                        </div>
                        <p style={{ marginLeft: 10, flex:1, flexGrow: 1, color: '#2E323A', fontSize: 24, lineHeight: 1.5 }}>{name}</p>
                    </div>
                }
                extra={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontWeight: 'normal', color: '#5B92C3', fontSize: 20, textAlign: 'center', fontStyle: 'italic', textAlign: 'right' }}>{framework}</span>
                        <img src={require('../assets/down-arrow.svg')} style={{ width: 60, height: 20, transform: `rotate(${isOpen ? 180 : 0}deg)` }} />
                    </div>
                }
            >
                <div style={{ marginLeft: props.isPortrait ? 0 : 50, marginRight: props.isPortrait ? 0 : 50 }}>
                    <p style={{ marginTop: 0 }}>{description}</p>
                    <div>
                        { images.map(image => (
                            <div style={{ width: '100%' }}>
                                <img src={require(`../assets/projects/${image}`)} style={{ width: '100%' }}/>
                            </div>
                        ))}
                    </div>
                </div>
            </Panel>
        </Collapse>
    );
}
