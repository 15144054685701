import React from 'react';
import logo from '../logo.svg';
import npm from '../npm.svg';
import { useMediaQuery } from 'react-responsive'
import '../App.css';
import { Project, Timeline } from "../components"
import data from "../experience.json"

const { maintainer, contributor } = data.openSource

export function OpenSource() {

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })
    const isMobileDevice = useMediaQuery({
        query: '(max-device-width: 414px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const renderContribution = (library) => (
        <div style={{ marginBottom: 10, paddingBottom: 10, borderColor: '#D40100', borderRadius: 4, paddingLeft: isPortrait && isMobileDevice ? 0 : 20, paddingRight: isPortrait && isMobileDevice ? 0 : 20 }}>
            <div style={{ display: 'flex', flexDirection: isPortrait ? 'column' : 'row', width: '100%', overflow: 'hidden', marginBottom: 20, height: 30 }}>
                <img src={npm} style={{ width: 60, height: 'auto', marginTop: 4 }}/>
                <a
                    href={library.urls[0]}
                    target="_blank"
                    style={{ marginLeft: isPortrait ? 0 :  20, color: '#4F3D76', fontWeight: 500, fontSize: 20, textDecoration: 'none' }}
                >{ library.name }</a>
            </div>
            <p style={{ marginBottom: 10, marginTop: 0, color:'#333333' }}>{ library.description }</p>
        </div>
    )

    return (
        <div style={{ padding: 20 }}>
            <h1 className="navbar-title">Maintainer</h1>
            <div style={{ paddingLeft: isPortrait ? 10 : 30, paddingRight: isPortrait ? 10 : 30 }}>
                { maintainer.map((m) => (
                    renderContribution(m)
                )) }
            </div>
            <h1 className="navbar-title">Contributor</h1>
            <div style={{ paddingLeft: isPortrait ? 10 : 30, paddingRight: isPortrait ? 10 : 30 }}>
                { contributor.map(m => (
                    renderContribution(m)
                )) }
            </div>
        </div>
    );
}
