import React from 'react';
import { Link } from "react-router-dom"
import { useMediaQuery } from 'react-responsive'
import '../App.css';
import { Expandable, Project, Timeline } from "../components"
import data from "../experience.json"
import { Workflow } from './Workflow';
import { Certificate } from './Certificate';

const { projects, companies } = data.architect

export function ArchExp() {

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 414px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const initialLanguageData = {
    'JavaScript': 0,
    'Swift': 0
  }

  const styles = {
    title: { 
        textAlign: isPortrait ? 'center' : 'left'
    },
  }
  

  const [selectedLanguage, setSelectedLanguage] = React.useState('JavaScript')

  const [selectedCompany, setSelectedCompany] = React.useState(null)
  const [projectsOfSelectedCompany, setProjectsOfSelectedCompany] = React.useState(projects)
  const [languageData, setLanguageData] = React.useState(initialLanguageData)
  const [isFreelancing, setIsFreelancing] = React.useState(true)

  React.useEffect(() => {
    if (selectedCompany) {
     
      setProjectsOfSelectedCompany(projects.filter(project => {
        console.log('company:', selectedCompany.name, 'project:', project.company, project.company === selectedCompany.name)
        return project.company === selectedCompany.name
      }))
    } else {
      setProjectsOfSelectedCompany(projects)
    }
    setSelectedLanguage(null)
  }, [selectedCompany])

  React.useEffect(() => {
    let languageData = initialLanguageData
    projectsOfSelectedCompany.map(project => {
      const { language } = project
      languageData[language] = languageData[language] + 1
    })
    setLanguageData(languageData)
  }, [projectsOfSelectedCompany])

  React.useEffect(() => {
    if (selectedCompany && selectedCompany.name == 'Freelancing') {
      setIsFreelancing(true)
    } else {
      setIsFreelancing(false)
    }
  }, [selectedCompany])

  const filteredProjects = () => {
    return projectsOfSelectedCompany.filter(project => {
      if (selectedLanguage) {
        return project.language === selectedLanguage
      }
      return project
    })
  }

  const renderResponsibility = (type, content, isDevOps = false, link = null) => {
    return (
      <div style={{ display: 'flex', flexDirection: isPortrait && isMobileDevice ? 'column' : 'row', marginBottom: 20, width: '100%' }}>
        <div style={{ width: isDevOps ? '30%' : '30%', maxWidth: 100 }}>
          <img src={require(`../assets/badges/${type}.png`)} style={{ width: '100%', height: 'auto' }} />
        </div>
        
        <div style={{ flex: 1}} >
          <p style={{ margin: 0, marginLeft: 20, marginRight: 20 }}>{content}</p>
          { link && 
            <div className='link-button'>
              <Link to="/workflow" activeClassName="link-button">Workflow Diagrams and Toolkits</Link>
            </div>
          }
        </div>
      </div>
    )
  }

  const renderWorkflow = () => (
    <div>
      <h1 style={styles.title}>DevOps Workflows Showcase</h1>
      <Expandable
        title='Multi-environment Deployment & QA Automation Pipeline'
      >
        <Workflow/>
      </Expandable>
    </div>
  )

  const renderArchitecture = () => (
    <div>
      <h1 style={styles.title}>Architecture Diagram Highlights</h1>
      <Expandable
        title='Modernization of E-commerce Website through Container Orchestration (Elastic Container Service vs Kubernetes)'
        remainOpen={true}
      >
        <div style={{ width: '80%' }}>
          {/* <h2>Cloud Agnostic Architecture Design:</h2>
          <div style={{ width: '100%' }}>
            <img src={require(`../assets/diagrams/e-commerce_cloud_agnostic.png`)} style={{ width: '100%' }}/>
          </div> */}
          {/* <h2>Microservices using ECS(Elastic Container Service / Fargate):</h2> */}
          <div style={{ width: '100%' }}>
            <img src={require(`../assets/diagrams/e-commerce_ecs.png`)} style={{ width: '100%' }}/>
          </div>
        </div>
      </Expandable>
      
      <Expandable
        title='Resilient & Scalable Multi-server Architecture of Wordpress Websites on AWS'
        remainOpen={false}
      >
        <div style={{ width: '80%' }}>
          <img src={require(`../assets/diagrams/wordpress_aws.png`)} style={{ width: '100%' }}/>
          <ul>
            <li>Amazon CloudFront to cache and serve assets to speed up content delivery.</li>
            <li>Memcached plugin (such as W3 Total Cache) to cache frequently accessed data in memory to reduce latency and increase throughout</li>
            <li>Run website instances in an ASG(Auto Scaling Group) of EC2 instances to dynamically adjust number of website instances by traffic load. </li>
            <li>Route traffic through ALB(Application Load Balancer) to distribute traffic to website instances in the ASG, and utilize ALB health checking to trigger CloudWatch alarm if abnormality is detected.</li>
            <li>Mount distributed file system, Amazon EFS, to EC2 instances for sharing Wordpress data. Backup EFS regularly.</li>
            <li>Amazon Aurora (primary + read replicas) to handle failover. Take database snapshot regularly.</li>
            <li>NAT Gateway to enable EC2 instances accessing the Internet while keeping them in private subnets. Place bastion hosts in public subnets for development accessing.</li>
          </ul>
        </div>
      </Expandable>

      <Expandable
        title='Serverless Microservices (Lambda + API Gateway)'
        remainOpen={false}
      >
        <div style={{ width: '80%' }}>
          <img src={require(`../assets/diagrams/lambda_serverless.png`)} style={{ width: '100%' }}/>
        </div>
      </Expandable>
    </div>
  )

  const renderCertificate = () => (
    <div>
      <h1 style={{...styles.title, marginBottom: 40}}>Certificates</h1>
      <Certificate/>
    </div>
  )
   

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Timeline
          companies={companies}
          isSimplified={isPortrait && isMobileDevice}
          onSelect={(company) => {
              setSelectedCompany(company)
          }} 
        />
        {/* Certificates */}
        {isFreelancing && renderCertificate()}

        {/* Responsibilities */}
        { selectedCompany && 
          <h1 style={{...styles.title, marginBottom: 40}}>
            Responsibilites
          </h1> 
        }
        { selectedCompany && 
          <div style={{ display: 'flex', flexDirection: isPortrait || isTabletOrMobileDevice ? 'column' : 'row', flexWrap: 'wrap' }}>
            { Object.entries(selectedCompany.responsibilities).map(([type, content]) => renderResponsibility(type, content)) }
          </div>
        }

        {/* Architectures */}
        {isFreelancing && renderArchitecture()}

        {/* Workflows */}
        {!isFreelancing && renderWorkflow()}

        {/* Projects */}
        { selectedCompany && !isFreelancing &&<h1 style={styles.title}>Projects</h1> }
        <div style={{ marginBottom: 300 }}>
        { filteredProjects().map(project => (
            <Project key={project.name} project={project} isPortrait={isMobileDevice} remainOpen={ filteredProjects().length === 1 ? true : false }></Project>
        )) }
        </div>
        
    </div>
  );
}
