import React from 'react';
import { useMediaQuery } from 'react-responsive';
import data from "../experience.json"

const { containerization, cloudService, projectManagement } = data.achievement.certificate

export function Certificate() {
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isMobileDevice = useMediaQuery({
        query: '(max-device-width: 414px)'
    })

    const renderCertificate = (cert) => (
        <div style={{ marginBottom: 10, paddingBottom: 10, borderColor: '#D40100', borderRadius: 4, paddingLeft: isPortrait && isMobileDevice ? 0 : 20, paddingRight: isPortrait && isMobileDevice ? 0 : 20 }}>
        <div style={{ display: 'flex', flexDirection: isPortrait ? 'column' : 'row', alignItems: 'center', width: '100%', overflow: 'hidden', marginBottom: 20, height: 'auto' }}>
            <img src={require(`../assets/certificates/${cert.badge}`)} style={{ width: 100, height: 'auto', margin: 4 }}/>
            <div style={{ marginLeft: isPortrait ? 0 :  20, display: 'flex', flexDirection: 'column', alignItems: isPortrait ? 'center' : 'flex-left'}}>
                <a
                    href={cert.urls[0]}
                    target="_blank"
                    style={{ color: '#4F3D76', fontWeight: 500, fontSize: 26, textDecoration: 'underline' }}
                >{ cert.name }</a>
                <p style={{ fontStyle: 'italic', fontSize: 22, marginBottom: 0, textAlign: isPortrait ? 'center' : 'left' }}>Issued by <b>{ cert.issuedBy }</b>, { cert.issuedAt }</p>
            </div>
        </div>
    </div>
    )

    const renderCertCategory = (category, certs) => (
        <div style={{ padding: 0 }}>
            <h2 className='navbar-title'>{category}</h2>
            <div style={{ paddingLeft: isPortrait ? 10 : 30, paddingRight: isPortrait ? 10 : 30 }}>
                {
                    certs.map((cert) => (
                        renderCertificate(cert)
                    ))
                }
            </div>
        </div>
    )

    return (
        <div>
            { renderCertCategory('Cloud Service', cloudService) }
            { renderCertCategory('Containerization', containerization) }
            { renderCertCategory('Project Management', projectManagement) }
        </div>
    )
}