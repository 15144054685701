import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import './App.css';
import { Experience, AboutMe, Certificate, Workflow, DevExp, ArchExp } from './screens'

const header_icons = [
  './assets/architect-icon.png',
  './assets/developer-icon.png',
  './assets/profile.jpg'
]

const header_titles = [
  'Solution Architect',
  'Developer',
  'About Me'
]

const paths = {
  root: '/',
  cert: '/certificates',
  me: '/about-me',
  workflow: '/workflow',
  dev: '/developer',
  arch: '/architect'
}

const routes = [
  {
    path: paths.dev,
    bg: './assets/header_2.png',
    icon: header_icons[1],
    title: header_titles[1]
  },
  {
    path: paths.me,
    bg: './assets/header_3.png',
    icon: header_icons[2],
    title: header_titles[2]
  },
  {
    path: paths.root,
    bg: './assets/header_1.png',
    icon: header_icons[0],
    title: header_titles[0]
  }
]

const navbarButtons = [
  {
    icon: header_icons[0],
    title: header_titles[0],
    path: paths.root
  },
  {
    icon: header_icons[1],
    title: header_titles[1],
    path: paths.dev
  },
  {
    icon: header_icons[2],
    title: header_titles[2],
    path: paths.me
  }
]

function App() {
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const renderNavbarBg = (path, bg) => {
    return (
      <Route path={path}>
        <img src={require("" + bg)} className="navbar-bg" />
      </Route>
    )
  }
  
  const renderNavbarButton = (title, icon, to) => {
    return (
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className='navbar-button'>
          <NavLink className="navbar-button" activeClassName="App-active-link" exact to={to}>
            <img className="navbar-icon" src={require("" + icon)} />
            <span>{title}</span>
          </NavLink>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="page">
        <Router>
          <header className="navbar">
            <div className='navbar-header'>
              <Switch>
                { routes.map(({ path, bg }) => renderNavbarBg(path, bg)) }
              </Switch>
              <div className='navbar-button-container'>
                { navbarButtons.map(({ title, icon, path }) => renderNavbarButton(title, icon, path)) }
              </div>
            </div>
          </header>
          <Switch>
            <Route path={paths.dev}>
              <DevExp />
            </Route>
            <Route path={paths.cert}>
              <Certificate />
            </Route>
            <Route path={paths.me}>
              <AboutMe />
            </Route>
            <Route path={paths.workflow}>
              <h1 style={{ textAlign: isPortrait ? 'center' : 'left' }}>DevOps Workflows Showcase</h1>
              <Workflow />
            </Route>
            <Route path={paths.root}>
              <ArchExp />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
