import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Collapse, { Panel } from 'rc-collapse';
import '../App.css'

require('rc-collapse/assets/index.css');


export function Expandable(props) {
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const { title = '', remainOpen = false } =  props

    const [isOpen, setIsOpen] = React.useState( remainOpen ? true : false)

    return (
        <Collapse
            accordion={true}
            onChange={() => { setIsOpen(!isOpen) }}
            style={{ width: '100%' }}
            defaultActiveKey={ remainOpen ? title : null}
        >
            <Panel
                key={title}
                showArrow={false}
                header={
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', minHeight: 100}}>
                        <h3 style={{ display: 'inline', lineHeight: 1.2 }}>{title}</h3>
                    </div>
                }
                extra={
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <img src={require('../assets/down-arrow.svg')} style={{ width: 60, height: 20, transform: `rotate(${isOpen ? 180 : 0}deg)` }} />
                    </div>
                }
            >
                {props.children}
            </Panel>
        </Collapse>
    );
}
