import React from 'react';
import { useMediaQuery } from 'react-responsive'
import '../App.css';
import { Project, Timeline } from "../components"
import data from "../experience.json"

const { descriptions, github, linkedIn, gmail, skills, languages, education } = data.aboutMe
const { technical, soft } = skills

export function AboutMe() {

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isMobileDevice = useMediaQuery({
      query: '(max-device-width: 414px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

  const renderContactButtons = () => {
    return (
      <div style={{ paddingTop: 10, paddingBottom: 10 }}>
        <div style={{ display: 'flex', flexDirection: isPortrait ? 'column' : 'row' }}>
          <a href={`mailto:${gmail}`} style={{ textDecoration: 'none' }}>
            <button className="App-button">
              <img src={require('../assets/gmailIcon.png')} style={{ width: 40, height: 40, marginRight: 10, borderRadius: '50%' }}></img>
              <span>Email Me</span>
            </button>
          </a>
          <a href={linkedIn} style={{ textDecoration: 'none' }}>
            <button className="App-button">
              <img src={require('../assets/linkedInLogo.png')} style={{ width: 40, height: 40, marginRight: 10 }}></img>
              <span>Find My LinkedIn</span>
            </button>
          </a>
        </div>
      </div>
    )
  }

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <h1 className="navbar-title">About me</h1>
        <div style={{ display: 'flex', flexDirection: isPortrait ? 'column' : 'row', alignItems: 'center' }}>
          { !isPortrait &&
            <div style={{ width: '50%', height: 'auto', marginTop: 5 }}>
              <img src={require('../assets/profile_2.jpg')} style={{ width: 300, height: 'auto', borderTopRightRadius: 40, borderBottomLeftRadius: 40 }}/>
            </div>
          }
          <div style={{ marginLeft: 50 }}>
            {
              descriptions.map((description) => (
                <p style={{ marginTop: 0 }}>{description}</p>
              ))
            }
          </div>
        </div>
        { renderContactButtons() }
      {/* Skills */}
      <div>
        <h1 style={{ textAlign: isPortrait ? 'center' : 'left', color: '#2E323A', marginTop: 50, marginBottom: 40 }}>{'My Toolkit'}</h1>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} >
          { technical.concat(soft).map(skill => {
            return (
              <div style={{ display: 'flex', flexDirection: isPortrait && isMobileDevice ? 'column' : 'row', width: isPortrait || isTabletOrMobileDevice ? '100%' : '50%', marginTop: 10, marginBottom: 10 }}>
                <img src={require(`../assets/badges/${skill.icon}`)} style={{ width: 80, height: 80, marginRight: 20 }} />
                <div>
                  <h3 style={{ margin: 0 }}>{skill.title}</h3>
                  <p style={{ margin: 0 }}>{skill.subtitle}</p>
                </div>
              </div>
            )
          }) }
        </div>
      </div>
      {/* Education */}
      <div style={{ marginBottom: 100 }}>
        <h1 style={{ textAlign: isPortrait ? 'center' : 'left', color: '#2E323A', marginTop: 50, marginBottom: 40 }}>{'Education'}</h1>
        <div>
          {education.map(edu => {
            return (
              <div style={{ marginBottom: 40 }}>
                <p>{`${edu.name} - ${edu.peroid}`}</p>
                <p style={{ fontStyle: 'italic' }}>{edu.subject}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
