import React from 'react';
import { useMediaQuery } from 'react-responsive';
import data from "../experience.json"

const { companies } = data.work

export function Workflow() {
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const company = companies.filter(c => c.name == 'TTT Studios')[0]
    
    const renderDevopsWorkflow = (workflow) => {
        const { phase, description, diagram, tools } = workflow
        return (
          <div style={{ margin: 20 }}>
            <h2>{phase}</h2>
            <div style={{ display: 'flex', flexDirection: isPortrait ? 'column' : 'row', background: '#DBE4EC', borderRadius: 20 }}>
              <img src={require(`../assets/devops/${diagram}`)} style={{ margin: 20, width: isPortrait ? '80%' : '40%', height: 'auto' }}/>
              <div style={{ margin: 10 }}>
                <p>{description}</p>
                { 
                  tools.map(tool => (
                    <img src={require(`../assets/devops/${tool}.png`)} style={{ height: 40, width: 'auto', marginRight: 10 }} /> 
                  ))
                }
              </div>
            </div>
          </div>
        )
    }

    return (
        <div>
          { company.devops.workflows.map((w) => renderDevopsWorkflow(w)) }
          
        </div>
    )
}