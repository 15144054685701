import React from 'react';


export function Timeline(props) {
    const [ selectedCompany, setSelectedCompany ] = React.useState(props.companies[0])
    React.useEffect(() => {
        props.onSelect(selectedCompany)
    }, [])

    const renderBranch = (company, isTop = false) => {
        const opacity = selectedCompany.name === company.name ? 1 : 0.2
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                { !isTop && <div style={{ width: 4, height: 30, backgroundColor: company.color || 'blue', opacity }}></div>}
                <div style={{ width: 10, height: 10, backgroundColor: company.color || 'blue', borderRadius: 5, opacity }}></div>
                { isTop && <div style={{ width: 4, height: 30, backgroundColor: company.color || 'blue', opacity }}></div>}
            </div>
        )
    }

    const renderIcon = (company, isTop) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            { !isTop && renderBranch(company, isTop) }
            <img 
                src={require(`../assets/icons/${company.icon}`)}
                style={{ width: 50, height: 50, borderRadius: 25, marginTop: 10, marginBottom: 10, opacity: selectedCompany.name === company.name ? 1 : 0.2 }}
            />
            { isTop && renderBranch(company, isTop) }
        </div>
        // <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{company.name}</p>
    )
     
    const renderPeroid = (company, isTop = false) => {
        if (props.isSimplified) {
            return null
        }
        return (
            <div 
                style={{ width: '100%', height: 40, backgroundColor: company.color || 'orange', 
                borderTopLeftRadius: isTop ? 6 : 0, 
                borderTopRightRadius: isTop ? 6 : 0, 
                borderBottomLeftRadius: !isTop ? 6 : 0, 
                borderBottomRightRadius: !isTop ? 6 : 0, 
                opacity: selectedCompany.name === company.name ? 1 : 0.2,
                overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <span style={{ color: 'white', fontWeight: 'bold' }}>{company.peroid}</span>
            </div>
        )
    }

    const renderNameAndPeroid = (company, isTop = false) => (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
            { isTop ? renderIcon(company, isTop) : renderPeroid(company, isTop) }
            { isTop ? renderPeroid(company, isTop) : renderIcon(company, isTop)}
        </div>
    )

    const renderDescription = (company, isTop = false) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: isTop ? 'flex-end' : 'flex-start', opacity: selectedCompany.name === company.name ? 1 : 0.2, height: '100%'}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {<span style={{ fontWeight: 'bold', textAlign: 'center', color: '#2E323A', marginTop: 10 }}>{ props.isSimplified && company.alias ? company.alias : company.name}</span>}
                </div>
                { !props.isSimplified && <p style={{ textAlign: 'center', color: '#2E323A', fontWeight: 500 }}>{ company.description }</p>}
            </div>
        )
    }

    const renderCompany = (company, index, isTop = false) => (
        <div style={{ width: `${100/props.companies.length}%` }} onClick={() => {
            setSelectedCompany(company)
            props.onSelect && props.onSelect(company)
        }}>
            {   index % 2 === ( isTop ? 0 : 1 ) ?
                renderNameAndPeroid(company, isTop) : 
                renderDescription(company, isTop)
            }
        </div>
    )

    if (props.companies.length <= 1) {
        // Hide timeline if there's no more than one company.
        return null
    }

    return (
        <div style={{ marginTop: 30 }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                { props.companies.map((company, index) => renderCompany(company, index, true)) }
            </div>
            { props.isSimplified && <div style={{ width: '100%', height: 4, backgroundColor: '#294C7D' }}></div>}
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                { props.companies.map((company, index) => renderCompany(company, index)) }
            </div>
        </div>
    );
}
